import { Paragraph } from "~/components/ui/typography";
import clsx from "clsx";

export function Label({
	className,
	...labelProps
}: JSX.IntrinsicElements["label"]) {
	return (
		<label className={clsx("mb-2 inline-block", className)} {...labelProps}>
			<Paragraph size="body-small" color="secondary">
				{labelProps.children}
			</Paragraph>
		</label>
	);
}
